import { Close } from '@material-ui/icons';
import React from 'react';
import { forwardRef } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import * as styles from './ModalWrapper.module.css'

const ModalTag = ({ value }) => {
  function titlecase(value) {
    if (value.length === 0) return '';
    if (value.length === 1) return value.toUpperCase();
    const first = value[0]
    const rest = value.slice(1)
    return `${first.toUpperCase()}${rest}`
  }
  return (
    <div className={styles.tag}>{titlecase(value)}</div>
  )
}

const ModalWrapper =  forwardRef(function ModalWrapperComponent({ onClose, layerType, toggle, modalIcon, children, isOpen }, ref) {

  return (
    <Modal trapFocus isOpen={isOpen} toggle={toggle} backdrop={false} innerRef={ref}>
      <ModalBody>
        <header className={styles.header}>
          <span className="static-icon">
            {Boolean(layerType !== 'polygon' && layerType !== 'polyline') && (<img style={{maxWidth: 24}} src={modalIcon} alt={layerType}/>)}
            <ModalTag value={layerType || ''}/>
          </span>
          <span className={styles.close} tabIndex={0} role="button" onClick={onClose}>
            <Close />
          </span>
        </header>
        <div className="content">{children}</div>
        <footer className="footer">
          <Button className={styles.submitClose} onClick={onClose}>Close</Button>
        </footer>
      </ModalBody>
    </Modal>
  );
})

export default ModalWrapper
