import React from 'react';
import * as styles from './MapWrapper.module.css'


export default function MapWrapper({ children }) {
  return (
    <div className={styles.root}>
      {children}
    </div>
  );
}
