import React from 'react';
import styles from './Footer.module.css'

export default function Footer() {
  return (
    <footer className={styles.root}>
      <div className={styles.container}>
      <span className={styles.section}>Copyright &copy; {new Date().getFullYear()} Dock411 LLC.
        <span>All Rights Reserved</span>
      </span>
      <span className={styles.section}>For more information,
        <span>contact <a className={styles.anchor} href="mailto:411@dock411.com?subject=D411 Facility Maps">411@dock411.com</a></span>
      </span>
      </div>
    </footer>
  );
}
