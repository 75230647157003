import L from 'leaflet'

export const icons = {
    marker_types: {
        yard_entrance: {
            name: "Yard Entrance",
                code: "yard_entrance",
                marker_url: "https://cdn.dock411.com/0000/brands/yard_entrance/marker-yard_entrance.svg",
                type: "point",
                multiple: true
        },
        security_hut: {
            name: "Security Hut",
                code: "security_hut",
                marker_url: "https://cdn.dock411.com/0000/brands/security_control/marker-security_control.svg",
                type: "point",
                multiple: true
        },
        dock: {
            name: "Dock",
                code: "dock",
                marker_url: "https://cdn.dock411.com/0000/brands/dock/marker-dock.svg",
                type: "point",
                multiple: false
        },
        accident: {
            name: "Accident",
                code: "accident",
                marker_url: "https://cdn.dock411.com/0000/brands/accident/marker-accident.svg",
                type: "point",
                multiple: true
        },
        debris: {
            name: "Debris",
                code: "debris",
                marker_url: "https://cdn.dock411.com/0000/brands/debris/marker-debris.svg",
                type: "point",
                multiple: true
        },
        low_clearance: {
            name: "Low Clearance",
                code: "low_clearance",
                marker_url: "https://cdn.dock411.com/0000/brands/low_clearance/marker-low_clearance.svg",
                type: "point",
                multiple: true
        },
        low_wires: {
            name: "Low Wires",
                code: "low_wires",
                marker_url: "https://cdn.dock411.com/0000/brands/low_wires/marker-low_wires.svg",
                type: "point",
                multiple: true
        },
        other: {
            name: "Other Hazard",
                code: "other",
                marker_url: "https://cdn.dock411.com/0000/brands/hazard/marker-hazard.svg",
                type: "point",
                multiple: true
        },
        pothole: {
            name: "Pothole",
                code: "pothole",
                marker_url: "https://cdn.dock411.com/0000/brands/pothole/marker-pothole.svg",
                type: "point",
                multiple: true
        },
        tight_turn: {
            name: "Tight Turn",
                code: "tight_turn",
                marker_url: "https://cdn.dock411.com/0000/brands/tight_turn/marker-tight_turn.svg",
                type: "point",
                multiple: true
        },
        work_zone: {
            name: "Work Zone",
                code: "work_zone",
                marker_url: "https://cdn.dock411.com/0000/brands/work_zone/marker-work_zone.svg",
                type: "point",
                multiple: true
        },
        parking: {
            name: "Parking",
                code: "parking",
                marker_url: "https://cdn.dock411.com/0000/brands/parking/marker-parking.svg",
                type: "point",
                multiple: true
        },
        man_door: {
            name: "Man Door",
                code: "man_door",
                marker_url: "https://cdn.dock411.com/0000/brands/man_door/marker-man_door.svg",
                type: "point",
                multiple: true
        },
        fuel: {
            name: "Fuel",
                code: "fuel",
                marker_url: "https://cdn.dock411.com/0000/brands/fuel/marker-fuel.svg",
                type: "point",
                multiple: true
        },
        maintenance: {
            name: "Maintenance",
                code: "maintenance",
                marker_url: "https://cdn.dock411.com/0000/brands/maintenance/marker-maintenance.svg",
                type: "point",
                multiple: true
        },
        scale: {
            name: "Scale",
                code: "scale",
                marker_url: "https://cdn.dock411.com/0000/brands/scale/marker-scale.svg",
                type: "point",
                multiple: true
        },
        staging: {
            name: "Staging Area",
                code: "staging_area",
                marker_url: "https://cdn.dock411.com/0000/brands/staging_area/marker-staging_area.svg",
                type: "point",
                multiple: true
        },
        washout: {
            name: "Washout",
                code: "washout",
                marker_url: "https://cdn.dock411.com/0000/brands/washout/marker-washout.svg",
                type: "point",
                multiple: true
        },
        sweepout: {
            name: "Sweepout",
                code: "sweepout",
                marker_url: "https://cdn.dock411.com/0000/brands/sweepout/marker-sweepout.svg",
                type: "point",
                multiple: true
        },
        big_box: {
            name: "Big Box",
                code: "big_box",
                marker_url: "https://cdn.dock411.com/0000/brands/big_box/marker-big_box.svg",
                type: "point",
                multiple: true
        },
        dealer: {
            name: "Dealer",
                code: "dealer",
                marker_url: "https://cdn.dock411.com/0000/brands/dealer/marker-dealer.svg",
                type: "point",
                multiple: true
        },
        parking_location: {
            name: "Parking Location",
                code: "parking_location",
                marker_url: "https://cdn.dock411.com/0000/brands/parking_location/marker-parking_location.svg",
                type: "point",
                multiple: true
        },
        rest_area: {
            name: "Rest Area",
                code: "rest_area",
                marker_url: "https://cdn.dock411.com/0000/brands/rest_area/marker-rest_area.svg",
                type: "point",
                multiple: true
        },
        restaurant: {
            name: "Restaurant",
                code: "restaurant",
                marker_url: "https://cdn.dock411.com/0000/brands/restaurant/marker-restaurant.svg",
                type: "point",
                multiple: true
        },
        truck_stop: {
            name: "Truck Stop",
                code: "truck_stop",
                marker_url: "https://cdn.dock411.com/0000/brands/truck_stop/marker-truck_stop.svg",
                type: "point",
                multiple: true
        },
        wash: {
            name: "Wash",
                code: "wash",
                marker_url: "https://cdn.dock411.com/0000/brands/wash/marker-wash.svg",
                type: "point",
                multiple: true
        },
        weigh_station: {
            name: "Weigh Station",
                code: "weigh_station",
                marker_url: "https://cdn.dock411.com/0000/brands/weigh_station/marker-weigh_station.svg",
                type: "point",
                multiple: true
        },
        do_not_enter: {
            name: "Do Not Enter",
                code: "do_not_enter",
                marker_url: "https://cdn.dock411.com/0000/brands/do_not_enter/marker-do_not_enter.svg",
                type: "point",
                multiple: true
        }
    }
}

export let setMarkerIconProperties = (icon) => {
    return  L.icon({
        iconUrl: icon.marker_url,
        iconAnchor:   [0, 60],
        iconSize:     [38, 95], // size of the icon
        /*shadowSize:   [50, 64], // size of the shadow
        iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
        shadowAnchor: [4, 62],  // the same for the shadow
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor*/
    })
};
