import React, {useCallback, useEffect, useRef, useState} from 'react'
import L from 'leaflet'
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import Tooltip from '@material-ui/core/Tooltip';
import 'leaflet-arrowheads'
import '../index.css'
import RoomIcon from '@material-ui/icons/Room';
import CallMadeIcon from '@material-ui/icons/CallMade';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import {BorderStyleOutlined, Close} from "@material-ui/icons";
import Logo from '../assets/logo_dock411__422A06F3F8.png'
import {icons, setMarkerIconProperties} from "../icon/icons";
import {Button, Modal, ModalBody, Form, FormGroup, Label, Input, Row, Col} from 'reactstrap';
import Draggable from "react-draggable";
import {
    initiateDrawPlugin,
    AREA_COLORS,
    AREA_STYLES,
    CODE_YARD,
    CODE_PARKING,
    CODE_OTHER,
    CODE_STAGING, CODE_SECURE, CODE_DO_NOT_ENTER,CODE_BUILDING
} from "../icon/constants";
import Header from './Header/Header';
import Footer from './Footer/Footer';
import AdminToolbar from './AdminToolbar/AdminToolbar';
import MapWrapper from './MapWrapper/MapWrapper';
import ModalWrapper from './ModalWrapper/ModalWrapper';

let Dock411FacilityMap = ({source, style, editMode}) => {
    const d411Json = source

    let parsedDock411Json = JSON.parse(d411Json)

    const [isAdmin, setIsAdmin] = useState(editMode);
    const [markerDrawer, setMarkerDrawer] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState({
        code: "",
        name: "",
        marker_url: "",
        type: "point",
        multiple: false
    });

    const [isDrawingInProgress, setIsDrawingInProgress] = useState(false);

    const [mapItems, setMapItems] = useState(parsedDock411Json)

    /*const [mapItems, setMapItems] = useState({
        version:  2,
        copyright: "Copyright &copy; 2022, Dock411 LLC. All Rights Reserved.",
        center: {
            "lat": 32.356015482789,
            "lng": -96.642683744431
        },
        markers: [],
        routes: [],
        areas: []
    })*/

    const [markers, setMarkers] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [areas, setAreas] = useState([]);

    const [modal, setModal] = useState(false);

    const [jsonModal, setJsonModal] = useState(false)

    const [isDeletingAction, setIsDeletingAction] = useState(false);

    const [selectedLayer, setSelectedLayer] = useState({
        layerType: '',
        id: '',
        name: '',
        type: ''
    });

    const [isArrowHead, setIsArrowHead] = useState(false)

    const toggle = useCallback(() => setModal(!modal), [modal]);
    const toggleJson = useCallback(() => setJsonModal(!jsonModal), [jsonModal]);

    const toggleMarkerDrawer = useCallback(() => setMarkerDrawer(!markerDrawer), [
        markerDrawer
    ])

    const [polygonOptions, setPolygonOptions] = useState({
        color: 'red',
        fill: false,
        fillColor: 'red',
        fillOpacity: 0.2,
        opacity: 0.5,
        stroke: true,
        weight: 4
    });

    const mapRef = useRef(null);
    const tileLayer = useRef(null);
    const googleLayer = useRef(null)
    const markersRef = useRef();
    const routesRef = useRef();
    const areasRef = useRef();
    const polygonOptionsRef = useRef();
    const layerBeingEditedRef = useRef();
    const selectedIconRef = useRef();
    const layerInFocus = useRef();
    const isArrowHeadRef = useRef();
    const arrowLineRef = useRef();
    const editableLayersRef = useRef(null);
    let drawControlRef = useRef(null);


    const sourceMarkersRef = useRef([]);
    const sourceAreasRef = useRef([]);
    const sourceRoutesRef = useRef([]);
    const modalRef = useRef(null);
    const jsonModalRef = useRef(null);
    markersRef.current = markers;
    routesRef.current = routes;
    areasRef.current = areas;
    polygonOptionsRef.current = polygonOptions;
    layerInFocus.current = null
    selectedIconRef.current = selectedIcon;
    isArrowHeadRef.current = isArrowHead;
    arrowLineRef.current = null

    // Modal click outside
    useEffect(() => {
    const listener = (event) => {
        if (!modalRef.current && !jsonModalRef.current) {
            return;
        }

        if (modalRef.current) {
            if (modal && event.target.classList.contains('modal')) {
                setModal(false);
            }
        }

        if (jsonModalRef.current) {
            if (jsonModal && event.target.classList.contains('modal')) {
                setJsonModal(false)
            }
        }

        };
        document.documentElement.addEventListener("mousedown", listener);
        document.documentElement.addEventListener("touchstart", listener);
  
        return () => {
          document.documentElement.removeEventListener("mousedown", listener);
          document.documentElement.removeEventListener("touchstart", listener);
        };

    }, [modalRef, modal, jsonModalRef, jsonModal])

    useEffect(() => {
        //create the map

        if(mapRef.current !== null){
            mapRef.current.remove()
        }
        mapRef.current = L.map('map', {scrollWheelZoom: false}).setView([isAdmin ? 39.8537380 : parsedDock411Json.center.lat, isAdmin ? -84.9253410 : parsedDock411Json.center.lng], 17);
        //set up the OSM layer
        tileLayer.current = L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        })

        googleLayer.current = L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        });

        let baseMaps = {
            "OpenStreetMap": tileLayer.current,
            "GoogleSatellite": googleLayer.current
        };

        L.control.layers(baseMaps).addTo(mapRef.current);
        const googleLayerExists = mapRef.current.hasLayer(googleLayer.current);

        if (!googleLayerExists) {
            mapRef.current.addLayer(googleLayer.current)
        }
        
        // initialize FeatureGroup is to store editable layers
        editableLayersRef.current = new L.FeatureGroup();
        const editableLayerExists = mapRef.current.hasLayer(editableLayersRef.current);

        if (!editableLayerExists) {
            mapRef.current.addLayer(editableLayersRef.current);
        }

        //initialize the leaflet drawing control on the top left
        drawControlRef.current = new L.Control.Draw(initiateDrawPlugin(editableLayersRef.current,isAdmin));
        mapRef.current.addControl(drawControlRef.current)

        if (isAdmin) {
            document.querySelector('a.leaflet-draw-draw-polyline').onclick = () => {
                setIsArrowHead(false);
            }
            document.querySelector('a.leaflet-draw-draw-polygon').onclick = () => {
                setIsArrowHead(false);
            }
        }
    }, [isAdmin, parsedDock411Json.center.lat, parsedDock411Json.center.lng ]);

    useEffect(() => {
        setMapItems({
            ...mapItems,
            routes: routes,
            markers: markers,
            areas: areas
        })
    }, [routes, markers, areas])

    useEffect(() => {
        mapRef.current.on('draw:created', _leafletDrawCreatedHandler);
        mapRef.current.on('draw:edited', _leafletDrawEditedHandler);
        mapRef.current.on('draw:deleted', _leafletDrawDeleteHandler);
        mapRef.current.on('draw:deletestart', _leafletDrawDeleteStartHandler);
        mapRef.current.on('draw:deletestop', _leafletDrawDeleteStopHandler);

    }, [isAdmin]);
    useEffect(() => {
        try {
            let JsonItem = mapItems;

            if (JsonItem.markers) {
                if (JsonItem.markers.length > 0) {


                    JsonItem.markers.map(value => {
                        let Icon = L.icon({
                            iconUrl: icons['marker_types'][value.code]['marker_url'],
                            iconAnchor: [0, 60],
                            iconSize: [38, 95], // size of the icon
                            /*shadowSize:   [50, 64], // size of the shadow
                            iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
                            shadowAnchor: [4, 62],  // the same for the shadow
                            popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor*/
                        })

                        sourceMarkersRef.current[value.id] = L.marker([value.lat, value.lng], {icon: Icon}).addTo(editableLayersRef.current).on('click', (e) => {
                            setSelectedLayer({
                                ...selectedLayer,
                                id:value.id,
                                name: value.name,
                                layerType: 'marker'
                            })
                            /*anesu*/
                            /*const index = markersRef.current.findIndex((marker) => marker.id === sourceMarkersRef.current[value.id]._leaflet_id);*/
                            setSelectedIcon({
                                ...selectedIcon,
                                marker_url: icons['marker_types'][value.code]['marker_url'],
                                code: value.code
                            })
                            toggle()
                        });

                        sourceMarkersRef.current[value.id].custom_id = value.id
                        sourceMarkersRef.current[value.id].layerType = 'marker'

                    })

                }
            }

            if (JsonItem.routes) {
                if (JsonItem.routes.length > 0) {

                    JsonItem.routes.map(value => {

                        let latLngsAsArray = []
                        value.vertices.forEach(v => {
                            latLngsAsArray.push([v.lat, v.lng])
                        })

                        if(value.isDirectional){
                            sourceRoutesRef.current[value.id] = L.polyline(value.vertices).arrowheads({size: '20px', frequency: 'allvertices'}).addTo(editableLayersRef.current).on('click',() => {
                                setSelectedLayer({
                                    ...selectedLayer,
                                    id:value.id,
                                    name: value.name,
                                    type: value.type,
                                    layerType: 'polyline'
                                })
                                toggle();
                            })



                            sourceRoutesRef.current[value.id].custom_id = value.id
                            sourceRoutesRef.current[value.id].layerType = 'polyline'
                            sourceRoutesRef.current[value.id].isDirectional = value.isDirectional

                        }else {


                            sourceRoutesRef.current[value.id] = L.polyline([latLngsAsArray]).on('click',() => {
                                setSelectedLayer({
                                    ...selectedLayer,
                                    id:value.id,
                                    name: value.name,
                                    type: value.type,
                                    layerType: 'polyline'
                                })
                                toggle();
                            }).addTo(editableLayersRef.current)

                            sourceRoutesRef.current[value.id].custom_id = value.id
                            sourceRoutesRef.current[value.id].layerType = 'polyline'
                            sourceRoutesRef.current[value.id].isDirectional = value.isDirectional
                        }

                    })
                }
            }


            if (JsonItem.areas) {
                if (JsonItem.areas.length > 0) {

                    JsonItem.areas.map(value => {

                        let latLngsAsArray = []

                        value.vertices.forEach(v => {
                            latLngsAsArray.push([v.lat, v.lng])
                        })

                        let stylesToSet = AREA_COLORS.filter(v => {
                            return v.type === value.type
                        })

                        sourceAreasRef.current[value.id] =  L.polygon([latLngsAsArray]).addTo(editableLayersRef.current).on('click', () => {

                            setSelectedLayer({
                                ...selectedLayer,
                                id:value.id,
                                name: value.name,
                                type: value.type,
                                layerType: 'polygon'
                            })

                            layerBeingEditedRef.current = sourceAreasRef.current[value.id]
                            toggle();

                        }).setStyle({
                            color: stylesToSet[0].border,
                            fillColor: stylesToSet[0].fill,
                            fillOpacity: 0.35
                        })

                        sourceAreasRef.current[value.id].custom_id = value.id
                        sourceAreasRef.current[value.id].layerType = 'polygon'

                    })

                }
            }

        } catch (e) {
            console.log(e)
        }


    }, [d411Json,isAdmin])
    //TODO: Change below to use useMemo?
    useEffect(() => {
        setRoutes(parsedDock411Json.routes)
        setMarkers(parsedDock411Json.markers)
        setAreas(parsedDock411Json.areas)
    }, []);

    let handleAreaChange = (e) => {
        setSelectedLayer({
            ...selectedLayer,
            type: e.target.value
        })
        updateLayerPropsHandler(e)
        switch (e.target.value) {
            case CODE_YARD:
                setPolygonOptions({
                    ...polygonOptions,
                    color: AREA_STYLES.yard.border,
                    fillColor: AREA_STYLES.yard.fill,
                    fillOpacity: 0.35
                })
                layerBeingEditedRef.current.setStyle(
                    {
                        color: AREA_STYLES.yard.border,
                        fillColor: AREA_STYLES.yard.fill,
                        fillOpacity: 0.35
                    }
                )
                return;
            case CODE_PARKING:
                setPolygonOptions({
                    ...polygonOptions,
                    color: AREA_STYLES.parking.border,
                    fillColor: AREA_STYLES.parking.fill,
                    fillOpacity: 0.35
                })
                layerBeingEditedRef.current.setStyle(
                    {
                        color: AREA_STYLES.parking.border,
                        fillColor: AREA_STYLES.parking.fill,
                        fillOpacity: 0.35
                    }
                )
                return;
            case CODE_OTHER:
                setPolygonOptions({
                    ...polygonOptions,
                    color: AREA_STYLES.other.border,
                    fillColor: AREA_STYLES.other.fill,
                    fillOpacity: 0.35
                })
                layerBeingEditedRef.current.setStyle(
                    {
                        color: AREA_STYLES.other.border,
                        fillColor: AREA_STYLES.other.fill,
                        fillOpacity: 0.35
                    }
                )
                return;
            case CODE_STAGING:
                setPolygonOptions({
                    ...polygonOptions,
                    color: AREA_STYLES.staging.border,
                    fillColor: AREA_STYLES.staging.fill,
                    fillOpacity: 0.35
                })
                layerBeingEditedRef.current.setStyle(
                    {
                        color: AREA_STYLES.staging.border,
                        fillColor: AREA_STYLES.staging.fill,
                        fillOpacity: 0.35
                    }
                )
                return;
            case CODE_SECURE:
                setPolygonOptions({
                    ...polygonOptions,
                    color: AREA_STYLES.secure.border,
                    fillColor: AREA_STYLES.secure.fill,
                    fillOpacity: 0.35
                })
                layerBeingEditedRef.current.setStyle(
                    {
                        color: AREA_STYLES.secure.border,
                        fillColor: AREA_STYLES.secure.fill,
                        fillOpacity: 0.35
                    }
                )
                return;
            case CODE_DO_NOT_ENTER:
                setPolygonOptions({
                    ...polygonOptions,
                    color: AREA_STYLES.do_no_enter.border,
                    fillColor: AREA_STYLES.do_no_enter.fill,
                    fillOpacity: 0.35
                })
                layerBeingEditedRef.current.setStyle(
                    {
                        color: AREA_STYLES.do_no_enter.border,
                        fillColor: AREA_STYLES.do_no_enter.fill,
                        fillOpacity: 0.35
                    }
                )
                return;
            case CODE_BUILDING:
                setPolygonOptions({
                    ...polygonOptions,
                    color: AREA_STYLES.building.border,
                    fillColor: AREA_STYLES.building.fill,
                    fillOpacity: 0.35
                })
                layerBeingEditedRef.current.setStyle(
                    {
                        color: AREA_STYLES.building.border,
                        fillColor: AREA_STYLES.building.fill,
                        fillOpacity: 0.35
                    }
                )
                return;
            default:
                setPolygonOptions({
                    ...polygonOptions,
                    color: AREA_STYLES.other.border,
                    fillColor: AREA_STYLES.other.fill,
                    fillOpacity: 0.35
                })
                layerBeingEditedRef.current.setStyle(
                    {
                        color: AREA_STYLES.other.border,
                        fillColor: AREA_STYLES.other.fill,
                        fillOpacity: 0.35
                    }
                )
        }
    }

    let updateLayerPropsHandler = (e) => {

        if (selectedLayer.layerType === "polyline") {
            const index = routesRef.current.findIndex((route) => route.id === selectedLayer.id);

            let tempRoutes = [...routesRef.current];

            let tempRoute = tempRoutes[index];

            setSelectedLayer({
                ...selectedLayer,
                layerType: 'polyline',
                type: 'polyline',
                name: e.target.value,
                [e.target.name]: e.target.value
            })

            tempRoute[e.target.name] = e.target.value;

            setRoutes([...tempRoutes])
        } else if (selectedLayer.layerType === "polygon") {
            const index = areasRef.current.findIndex((route) => route.id === selectedLayer.id);

            let tempRoutes = [...areasRef.current];

            let tempRoute = tempRoutes[index];

            setSelectedLayer({
                ...selectedLayer,
                [e.target.name]: e.target.value
            })

            tempRoute[e.target.name] = e.target.value;

            setAreas([...tempRoutes])
        } else if (selectedLayer.layerType === "marker") {
            const index = markersRef.current.findIndex((marker) => marker.id === selectedLayer.id);

            let tempMarkers = [...markersRef.current];

            let tempMarker = tempMarkers[index];

            setSelectedLayer({
                ...selectedLayer,
                [e.target.name]: e.target.value
            })

            tempMarker[e.target.name] = e.target.value;

            setMarkers([...tempMarkers])
        }

    }

    const _leafletDrawCreatedHandler = (e) => {

        setIsDeletingAction(false)
        const {layer, layerType} = e;

        if (!isArrowHeadRef.current) {
            editableLayersRef.current.addLayer(layer);
        }

        /*
        this id can only be extracted after layer has been added to editable layers
        */
        const {_leaflet_id} = layer;

        layer.layerType = layerType

        if (layerType === "marker") {

            let markerObject = {
                id: _leaflet_id,
                lat: layer.getLatLng().lat,
                lng: layer.getLatLng().lng,
                name: selectedIconRef.current.name,
                code: selectedIconRef.current.code
            }

            setMarkers(arr => [...arr, markerObject]);
            setIsDrawingInProgress(false);
        }

        if (layerType === "polygon") {
            let areasObject = {
                id: _leaflet_id,
                name: "",
                type: CODE_OTHER,
                vertices: layer.getLatLngs()[0]
            }

            setAreas(arr => [...arr, areasObject]);
        }

        if (layerType === "polyline") {
            let routeObject = {
                id: _leaflet_id,
                name: "",
                type: "",
                vertices: layer.getLatLngs(),
                isDirectional: isArrowHeadRef.current
            }

            if (isArrowHeadRef.current) {
                layer.editing.disable()

                let arrowLine = L.polyline(layer.getLatLngs()).arrowheads({size: '20px', frequency: 'allvertices'})
                arrowLine.layerType = 'polyline'
                arrowLine.isDirectional = true

                arrowLine.addTo(editableLayersRef.current);

                routeObject.id = arrowLine._leaflet_id;

                arrowLine.on('click', (e) => {

                    layerBeingEditedRef.current = e.target;

                    const index = routesRef.current.findIndex((route) => route.id === e.target._leaflet_id);

                    let tempRoutes = [...routesRef.current];

                    let tempRoute = tempRoutes[index];

                    setSelectedLayer({
                        ...selectedLayer,
                        layerType: layerType,
                        id: e.target._leaflet_id,
                        name: tempRoute.name,
                        type: tempRoute.type
                    });
                    toggle();
                })
            }

            setRoutes(arr => [...arr, routeObject])
        }
        //click events for the created layer
        layer.on('click', function (e) {

            /**
             * grab layer type of
             * */
            layerBeingEditedRef.current = e.target;
            console.log('LAYER CLICK')
            if (layerType === "polyline") {
                const index = routesRef.current.findIndex((route) => route.id === e.target._leaflet_id);

                let tempRoutes = [...routesRef.current];

                let tempRoute = tempRoutes[index];

                setSelectedLayer({
                    ...selectedLayer,
                    layerType: layerType,
                    id: _leaflet_id,
                    name: tempRoute.name,
                    type: tempRoute.type
                });

            }

            if (layerType === "polygon") {
                const index = areasRef.current.findIndex((route) => route.id === e.target._leaflet_id);

                let tempRoutes = [...areasRef.current];

                let tempRoute = tempRoutes[index];

                setSelectedLayer({
                    ...selectedLayer,
                    layerType: layerType,
                    id: _leaflet_id,
                    name: tempRoute.name,
                    type: tempRoute.type
                });

            }

            if (layerType === "marker") {
                let markersToEdit = JSON.parse(JSON.stringify(markersRef.current));

                let edited_marker = markersToEdit.filter((item) => {
                    return item.id === e.target._leaflet_id
                })

                setSelectedLayer({
                    ...selectedLayer,
                    layerType: layerType, id: _leaflet_id, name: edited_marker[0].name, code: edited_marker[0].code
                })
                /*edited_marker[0].lat = value.getLatLng().lat
                edited_marker[0].lng = value.getLatLng().lng

                setMarkers(markersToEdit);*/
            }

            toggle();

        });
    }

    const _leafletDrawEditedHandler = (e) => {
        const {layers: {_layers}} = e;
        //loop through every layer edited layer

        Object.values(_layers).forEach((value, i) => {

                switch (value.layerType) {
                    case 'marker':

                        let markersToEdit = JSON.parse(JSON.stringify(markersRef.current));

                        let edited_marker = markersToEdit.filter((item) => {
                            return item.id === (value.editing._marker.custom_id ? value.editing._marker.custom_id : value._leaflet_id)
                        })

                        edited_marker[0].lat = value.getLatLng().lat
                        edited_marker[0].lng = value.getLatLng().lng

                        setMarkers(markersToEdit);

                        return
                    case 'polygon':

                        //setAreas([...areasRef.current]);

                        const indexArea = areasRef.current.findIndex((area) => area.id === (value.custom_id ? value.custom_id : value._leaflet_id));

                        let tempAreas = [...areasRef.current];

                        let tempArea = tempAreas[indexArea];

                        tempArea.vertices = value.editing.latlngs[0][0]

                        setAreas([...tempAreas])

                        return
                    case 'polyline':

                        const index = routesRef.current.findIndex((route) => route.id === (value.custom_id ? value.custom_id : value._leaflet_id));

                        let tempRoutes = [...routesRef.current];

                        let tempRoute = tempRoutes[index];

                        tempRoute.vertices = value.isDirectional ? value.editing.latlngs[0] : value.editing.latlngs[0][0]
                        setRoutes([...tempRoutes])
                        return
                }
        })

    }

    const _leafletDrawDeleteHandler = (e) => {
        const {layers: {_layers}} = e;

        //loop through every layer edited layer
        Object.values(_layers).forEach((value, i) => {
                switch (value.layerType) {
                    case 'marker':

                        let markersToEdit = JSON.parse(JSON.stringify(markersRef.current));
                        markersToEdit = markersToEdit.filter(el => el.id !== (value.custom_id ? value.custom_id : value._leaflet_id));
                        setMarkers(markersToEdit);

                        return
                    case 'polygon':

                        let areasToEdit = JSON.parse(JSON.stringify(areasRef.current));
                        areasToEdit = areasToEdit.filter(el => el.id !== (value.custom_id ? value.custom_id : value._leaflet_id));
                        setAreas(areasToEdit);

                        return
                    case 'polyline':

                        let routesToEdit = JSON.parse(JSON.stringify(routesRef.current));
                        routesToEdit = routesToEdit.filter(el => el.id !== (value.custom_id ? value.custom_id : value._leaflet_id));
                        setRoutes(routesToEdit);
                        return
                }
        })
    }

    const _leafletDrawDeleteStartHandler = (e) => {
        setIsDeletingAction(true)
    }

    const _leafletDrawDeleteStopHandler = (e) => {
        setIsDeletingAction(false)
        setModal(false)
    }

    const initiateDraw = useCallback(() => {
        setIsArrowHead(true);
        new L.Draw.Polyline(mapRef.current, drawControlRef.current.options.polyline).enable()
    }, [setIsArrowHead, mapRef, drawControlRef])

    let navIconClick = useCallback((value, e) => {
        setSelectedIcon(value);
        setIsArrowHead(false)
        if (isDrawingInProgress) {

        } else {
            setIsDrawingInProgress(true);
            new L.Draw.Marker(mapRef.current, {icon: setMarkerIconProperties(value)}).enable();
        }
    }, [isDrawingInProgress, setIsDrawingInProgress, setSelectedIcon, setIsArrowHead, mapRef])



    return (
        <>
            <link 
                rel="stylesheet" 
                href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
                integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
                crossOrigin=""/>
            <div>
                <Header isAdmin={isAdmin} setIsAdmin={setIsAdmin} Logo={Logo} />
                <MapWrapper>
                    <div id="map" style={style}>
                      <AdminToolbar 
                        isAdmin={isAdmin}
                        toggleJson={toggleJson}
                        icons={icons}
                        initiateDraw={initiateDraw}
                        markerDrawer={markerDrawer}
                        toggleMarkerDrawer={toggleMarkerDrawer}
                        navIconClick={navIconClick}/> 
                    </div>
                </MapWrapper>
                <Draggable>
                <ModalWrapper 
                        modalIcon={selectedIcon.marker_url}
                        layerType={selectedLayer.layerType}
                        ref={modalRef} 
                        onClose={() => setModal(false)}
                        isOpen={(modal && isDeletingAction === false)} 
                        toggle={toggle} >
                            <Form onSubmit={e => e.preventDefault()}>
                                {isAdmin ? (
                                    <>
                                        {selectedLayer.layerType === "marker" ? (
                                            <>
                                                {/*if this is marker layer type*/}
                                                <FormGroup>
                                                    <Label style={{fontSize: '0.889rem' }} for="name">Name</Label>
                                                    <Input 
                                                        tabIndex={0}
                                                        bsSize={'sm'}
                                                            type="text"
                                                            name="name" id="name" value={selectedLayer.name}
                                                            onChange={updateLayerPropsHandler}>
                                                    </Input>
                                                </FormGroup>
                                            </>
                                        ) : (
                                            <>
                                                <div>
                                                    <legend style={{ color: 'rgba(0,0,0,0.6)', fontSize: '0.889rem', fontWeight: 700 }}>Name</legend>
                                                    <FormGroup>
                                                            <Input bsSize={'sm'}
                                                                    style={{fontSize: '1rem'}}
                                                                   type="text"
                                                                   name="name" id="name"
                                                                   value={selectedLayer.name}
                                                                   onChange={updateLayerPropsHandler}/>
                                                        </FormGroup>
                                                </div>
                                                {/*is this is any layer type that is not a marker i.e. */}
                
                                                {selectedLayer.layerType === "polygon" && (
                                                    <>
                                                        <hr/>
                                                        <Row>
                                                            <Col sm={'3'}>
                                                                <legend style={{ color: 'rgba(0,0,0,0.6)', fontSize: '0.889rem', fontWeight: 700 }}>Type</legend>
                                                            </Col>
                                                            <Col sm={'9'}>
                                                                <FormGroup tag="fieldset">
                                                                    {AREA_COLORS.map((value, index) => {
                                                                        return (
                                                                            <FormGroup check key={index}
                                                                                       checked={value.type === selectedLayer.type}>
                                                                                <Label style={{fontSize: 14}} check>
                                                                                    <Input
                                                                                        style={{
                                                                                            borderColor: value.border,
                                                                                            backgroundColor: value.fill,
                                                                                            boxShadow: `0 0 0 0.25rem ${value.type === selectedLayer.type ? value.fill : '#fff'}`
                                                                                        }}
                                                                                        type="radio"
                                                                                        value={value.type}
                                                                                        tabIndex={0}
                                                                                        name="type"
                                                                                        onChange={handleAreaChange}
                                                                                    />{' '}
                                                                                    {value.name}
                                                                                </Label>
                                                                            </FormGroup>
                                                                        )
                                                                    })}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}
                                            </>
                                        )}</>
                                ) : (
                                    <>
                                        {selectedLayer.layerType === "marker" ? (
                                            <div>
                                                <span>Name: {selectedLayer.name}</span>
                                            </div>    
                                        ) : (
                                            <>
                                                <Row>
                                                    <Col sm={'4'} md={'4'} lg={'4'}>
                                                        <legend> <BorderStyleOutlined fontSize="large" htmlColor={'#e71d25'}/></legend>
                                                    </Col>
                                                    <Col sm={'8'} md={'8'} lg={'8'}>
                                                        <FormGroup>
                                                            <legend>{selectedLayer.name}</legend>
                                                            <span>{selectedLayer.type}</span>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </>
                                        )
                                        }
                                    </>
                                )}
                            </Form>
                    </ModalWrapper>
                </Draggable>
                <ModalWrapper ref={jsonModalRef} trapFocus isOpen={jsonModal} toggle={toggleJson} onClose={() => setJsonModal(false)}>
                    <h6>D411 Facility Map&trade; JSON</h6>
                    <div>
                        <textarea value={JSON.stringify(mapItems)} readOnly={true} rows="10" cols="63" style={{maxWidth: '100%'}}/>
                    </div>
                </ModalWrapper>
                <Footer />
            </div>
        </>
    )
}

export default Dock411FacilityMap;
