import React from 'react';
import * as styles from './AdminToolbar.module.css'
import RoomIcon from '@material-ui/icons/Room';
import CallMadeIcon from '@material-ui/icons/CallMade';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import { UncontrolledTooltip } from 'reactstrap';
import {icons } from "../../icon/icons";


export default function AdminToolbar({ isAdmin, toggleJson, markerDrawer, initiateDraw, toggleMarkerDrawer, navIconClick }) {
  const urlSafe = (value) => value.replace(/\s+/g, '_') 
  return (
    <div className={styles.root} style={{zIndex: 1050}}>
      {Boolean(isAdmin) && (
        <>
         <span className='d411-marker-selector' id="draw" onClick={initiateDraw}>
            <CallMadeIcon htmlColor='#464646'/>
            <UncontrolledTooltip style={{marginLeft: '0.83rem'}} target="draw" placement='right'>Draw Route</UncontrolledTooltip>
          </span>
          <span className='d411-marker-selector' id="place" onClick={toggleMarkerDrawer}>
            <RoomIcon htmlColor='#464646'/>
            <UncontrolledTooltip style={{marginLeft: '0.83rem'}} target="place" placement='right'>Place Marker</UncontrolledTooltip>
          </span>
          <div className="d411-marker-drawer-container" style={{display: markerDrawer ? 'block' : 'none'}}>
            {Object.entries(icons.marker_types).map((value, index) => {
              const id = urlSafe(value[1].name)
              return (
                  <span key={index}>
                      <img 
                        alt={value[1].name} 
                        onClick={(e) => navIconClick(value[1], e)}
                        src={value[1].marker_url} 
                        id={id}
                        style={{
                          fontSize: '1.75em',
                          verticalAlign: 'middle',
                          width: 35,
                          height: 27,
                          cursor: 'pointer',
                          margin: 4
                      }}></img>
                      <UncontrolledTooltip target={id} placement="top">
                        <span >{value[1].name}</span>
                    </UncontrolledTooltip>
                  </span>
              )
                })}
          </div>
        </>
      )}
     
        
        <span className='d411-marker-selector' id={'JSON'} onClick={toggleJson}>
        <SettingsEthernetIcon htmlColor='#464646'/>
          <UncontrolledTooltip target={'JSON'} placement="right" style={{marginLeft: '0.83rem'}}>
            <span>JSON</span>
          </UncontrolledTooltip>
        </span>
    </div>
  );
}
