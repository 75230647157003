import React from 'react';
import * as styles from './Header.module.css'

export default function Header({ Logo, setIsAdmin, isAdmin }) {
    const inactiveTabStyle = {
        color:'white',
        borderColor: `rgba(255,255,255,0.13)`
    }

    const activeTabStyle = {
        color:'#ff8300', 
        borderColor: `rgba(255, 131, 0, 0.35)`
    }
  return (
    <header className={styles.root}>
      <div className={styles.headerLeft}>
        <img className='logo' src={Logo} alt={'logo'}/>
        <h3>
            <div>D411 Facility Map<i>&trade;</i></div>
            <span>Better Maps for <b>Trucking</b></span>
        </h3>
      </div>
      <div className={styles.headerRight}>
        <h3 
            className='nav-links' 
            onClick={() => {
                setIsAdmin(false)
            }} 
            style={isAdmin ? 
                inactiveTabStyle : 
                activeTabStyle }>VIEW</h3>

        <span></span>
        <h3 
        className='nav-links' 
        onClick={() => {
            if(!isAdmin) {
                setIsAdmin(true)

                /*setMapItems({
                    version: 2,
                    copyright: "Copyright &copy; 2022, Dock411 LLC. All Rights Reserved.",
                    center: {
                        "lat": 32.356015482789,
                        "lng": -96.642683744431
                    },
                    markers: [],
                    routes: [],
                    areas: []
                })*/
            }
          }
        } 
        style={isAdmin ? activeTabStyle : inactiveTabStyle}>EDIT</h3>
      </div>
    </header>
  );
}
