export const PARKING = 'Parking'
export const DO_NOT_ENTER = 'Do Not Enter'
export const STAGING = 'Staging'
export const YARD = 'Yard'
export const SECURE = 'Secure'
export const OTHER = 'Other'
export const BUILDING = 'Building/Structure'

export const CODE_PARKING = 'parking'
export const CODE_DO_NOT_ENTER = 'do_not_enter'
export const CODE_STAGING = 'staging'
export const CODE_YARD = 'yard'
export const CODE_SECURE = 'secure'
export const CODE_OTHER = 'other'
export const CODE_BUILDING = 'building'

export const AREA_TYPES = [PARKING, DO_NOT_ENTER, STAGING, YARD, SECURE, OTHER,BUILDING];

export const DARK_GREY = '#2D3542'
export const AREA_COLORS = [
    {
        name: BUILDING,
        type: CODE_BUILDING,
        border: '#232731',
        fill: 'rgba(35,39,49,0.76)'
    },
    {
        name: DO_NOT_ENTER,
        type: CODE_DO_NOT_ENTER,
        border: '#e71d25',
        fill: 'rgb(231,29,37,0.35)'
    },
    {
        name: PARKING,
        type: CODE_PARKING,
        border: '#2bb6e9',
        fill: 'rgb(43,182,233,0.35)'
    },
    {
        name: SECURE,
        type: CODE_SECURE,
        border: '#73c054',
        fill: 'rgb(115,192,84,0.35)'
    },
    {
        name: STAGING,
        type: CODE_STAGING,
        border: '#ead94c',
        fill: 'rgb(234,217,76,0.35)'
    },
    {
        name: YARD,
        type: CODE_YARD,
        border: '#ff8300',
        fill: 'rgb(255,131,0,0.35)'
    },
    {
        name: OTHER,
        type: CODE_OTHER,
        border: '#7d53de',
        fill: 'rgb(125,83,222,0.35)'
    }

]

export const AREA_STYLES = {
    yard: AREA_COLORS[5],
    parking: AREA_COLORS[2],
    other: AREA_COLORS[6],
    staging: AREA_COLORS[4],
    secure: AREA_COLORS[3],
    do_no_enter: AREA_COLORS[1],
    building: AREA_COLORS[0]
}

export const mapStylesToAreaType = (category) => {
    switch (category) {
        case YARD:
            return {
                color: AREA_STYLES.yard.border,
                fillColor: AREA_STYLES.yard.fill,
                fillOpacity: 0.35
            }
        case PARKING:
            return {
                color: AREA_STYLES.parking.border,
                fillColor: AREA_STYLES.parking.fill,
                fillOpacity: 0.35
            }
        case OTHER:
            return {
                color: AREA_STYLES.other.border,
                fillColor: AREA_STYLES.other.fill,
                fillOpacity: 0.35
            }
        case STAGING:
            return {
                color: AREA_STYLES.staging.border,
                fillColor: AREA_STYLES.staging.fill,
                fillOpacity: 0.35
            }
        case SECURE:
            return {
                color: AREA_STYLES.secure.border,
                fillColor: AREA_STYLES.secure.fill,
                fillOpacity: 0.35
            }
        case DO_NOT_ENTER:
            return {
                color: AREA_STYLES.do_no_enter.border,
                fillColor: AREA_STYLES.do_no_enter.fill,
                fillOpacity: 0.35
            }
        case BUILDING:
            return {
                color: AREA_STYLES.building.border,
                fillColor: AREA_STYLES.building.fill,
                fillOpacity: 0.35
            }
        default:
            return {
                color: AREA_STYLES.other.border,
                fillColor: AREA_STYLES.other.fill,
                fillOpacity: 0.35
            }
    }
}

export const initiateDrawPlugin = (editableLayer,isAdmin) => {
    return {
        position: 'topleft',
        draw: {
            polyline: !isAdmin ? false : {
                shapeOptions: {
                    color: '#38f',
                    weight: 4,
                    opacity: 1
                }
            },
            polygon: !isAdmin ? false : {
                allowIntersection: true, // Restricts areas to simple polygons
                drawError: {
                    color: '#7d53de', // Color the shape will turn when intersects
                    message: '<strong>Polygon draw does not allow intersections!<strong> (allowIntersection: false)' // Message that will show when intersect
                },
                shapeOptions: {
                    color: '#7d53de',
                    opacity: 1
                }
            },
            circle: false, // Turns off this drawing tool
            circlemarker: false,
            rectangle: false,
            marker: false,
            draw: {
                circle: false
            }
        },
        edit: isAdmin ? {
            featureGroup: editableLayer, //REQUIRED!!
            remove: true
        } : false
    }
}
